import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import Snackbar from '@mui/material/Snackbar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import noteContext from './NoteContext';
import API from "./API";

function TeacherAsign() {
    const [data, setData] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [classes, setClasses] = useState([]);
    const [homeworks_data, sethomeworks] = useState([]);
    const [students, setStudents] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState('');
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    const [selectedhomework, setSelectedhomework] = useState('');
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [loadingbar, setHideLoading] = useState(false);
    const [assignedHomeworkv1, setAssignedHomeworkv1] = useState([]);

    const navigate = useNavigate();
    const a = useContext(noteContext);
    let token = a.token;

    const fetch_teachers_list = async () => {
        try {
            const res = await API.get("master/teachers_list", {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            setData(res.data.teachers);
            setHideLoading(true);
        } catch (error) {
            console.error("Error fetching teachers list:", error);
        }
    };

    const fetch_classes = async (teacherId) => {
        try {
            const res = await API.get(`master/classes_list/${teacherId}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            setClasses(res.data);
        } catch (error) {
            console.error("Error fetching classes list:", error);
        }
    };

    const fetch_subjects = async (classId) => {
        try {
            const res = await API.get(`master/Subjects/${classId}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            setSubjects(res.data);
        } catch (error) {
            console.error("Error fetching subjects list:", error);
        }
    };

    const fetch_homeworks = async (teacherId, classId, subjectId) => {
        try {
            const res = await API.get(`master/hw/${classId}/${subjectId}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            sethomeworks(res.data.homeworks);
        } catch (error) {
            console.error("Error fetching homeworks list:", error);
        }
    };

    const fetch_students = async (classId) => {
        const search_query = {
            "class": classId,
            "grade": "",
            "sr": "",
            "student_name": ""
        };

        try {
            const res = await API.post("search_students", search_query, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            setStudents(res.data);
            setHideLoading(true);
        } catch (error) {
            console.error("Error fetching students list:", error);
        }
    };

    const handleHomeworkSelection = (homeworkId) => {
        setSelectedhomework((prevSelected) =>
            prevSelected.includes(homeworkId)
                ? prevSelected.filter((id) => id !== homeworkId)
                : [...prevSelected, homeworkId]
        );
    };





    const handleSubmit = async () => {
        try {


            const res = await API.post('master/hw/assign_homework_to_teacher', {
                teacherId: selectedTeacher,
                classId: selectedClass,
                subjectId: selectedSubject,
                homework_id: selectedhomework
            }, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            setMessage('Homework assigned successfully!');
            setOpen(true);
        } catch (error) {
            console.error("Error submitting homework assignment:", error);
            setMessage('Failed to assign homework.');
            setOpen(true);
        }
    };

    useEffect(() => {
        if (a.login_status === null)
            navigate("/login")
        else {
            fetch_teachers_list();
        }
    }, []);

    return (
        <div>
            <div className="d-flex">
                <div className=" ">
                    <Select
                        value={selectedTeacher}
                        onChange={(e) => {
                            setSelectedTeacher(e.target.value);
                            fetch_classes(e.target.value);
                        }}
                        displayEmpty
                    >
                        <MenuItem value="" disabled>Select Teacher</MenuItem>
                        {data.length > 0 ? (
                            data.map((teacher) => (
                                <MenuItem key={teacher.id} value={teacher.id}>
                                    {teacher.email}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem value="" disabled>No Teachers Available</MenuItem>
                        )}
                    </Select>
                </div>

                <div className=" ">
                    {selectedTeacher && (
                        <div>
                            <Select
                                value={selectedClass}
                                onChange={(e) => {
                                    setSelectedClass(e.target.value);
                                    fetch_subjects(e.target.value);
                                }}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>Select Class</MenuItem>
                                {classes.map((cls) => (
                                    <MenuItem key={cls} value={cls}>
                                        {cls}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    )}
                </div>
                <div className=" ">
                    {selectedClass && (
                        <div>
                            <Select
                                value={selectedSubject}
                                onChange={(e) => {
                                    setSelectedSubject(e.target.value);
                                    fetch_homeworks(selectedTeacher, selectedClass, e.target.value);
                                }}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>Select Subject</MenuItem>
                                {subjects.map((subject) => (
                                    <MenuItem key={subject} value={subject}>
                                        {subject}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    )}
                </div>
            </div>

            <div>
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={() => setOpen(false)}
                    message={message}
                />
                <hr />

                <div>
                    {homeworks_data.length > 0 ? (
                        homeworks_data.map((homework, index) => (
                            <div key={index}>
                                <Checkbox
                                    onChange={() => handleHomeworkSelection(homework.homework_id)}
                                />
                                <strong>Homework:</strong> {homework.homework_id} -- {homework.homework}
                                <br />
                                <strong>Students:</strong>
                                <ul>
                                    {homework.srs.map((student, idx) => (
                                        <li key={idx}>
                                            {student.name} (SR: {student.sr}, Grade: {student.grade})
                                        </li>
                                    ))}
                                </ul>
                                <br />
                            </div>
                        ))
                    ) : (
                        <p>No homework available</p>
                    )}
                </div>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </div>
        </div>
    );
}

export default TeacherAsign;