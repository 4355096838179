import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from "react-router-dom";
import noteContext from './NoteContext';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import LaunchIcon from '@mui/icons-material/Launch';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import API from "./API";

export default function MarkComplete() {
    const [homeworks, setHomeworks] = useState([]);
    const [students, setStudents] = useState([]);
    const { school_class } = useParams();
    const data = useContext(noteContext);
    const [drawerState, setDrawerState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const token = data.token;

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState({ ...drawerState, [anchor]: open });
    };

    const fetchHomeWorks = async (school_class) => {
        try {
            const res = await API.get(`/hw/hw_list_v2/${school_class}`, {
                headers: {
                    'content-type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            setStudents(res.data);
        } catch (error) {
            console.error("An error occurred while fetching homeworks:", error);
        }
    };

    const deleteHomework = async (item) => {
        try {
            await API.post("delete_homework", item, {
                headers: {
                    'content-type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            fetchHomeWorks(school_class);
        } catch (error) {
            console.error("An error occurred while deleting homework:", error);
        }
    };

    const markCompleted = async (item) => {
        item.status = "Completed";
        item.remark = ".";
        try {
            await API.post("homework_mark_complete", item, {
                headers: {
                    'content-type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            fetchHomeWorks(school_class);
        } catch (error) {
            console.error("An error occurred while marking homework as completed:", error);
        }
    };

    const displayStudentRows = (student_home) => {
        return student_home.home_works.map((item, index) => (
            <tr key={index}>
                <td>{item.id}</td>
                <td>{item.homework}</td>
                <td>{item.created_at}</td>
                <td>
                    {item.status}
                    {item.status === "Completed" && <>{item.updated_at}</>}
                    <Button onClick={() => markCompleted(item)} variant="contained">
                        <DoneAllIcon />
                    </Button>
                </td>
                <td>
                    <Button onClick={() => deleteHomework(item)} variant="contained">
                        <DeleteForeverIcon />
                    </Button>
                </td>
            </tr>
        ));
    };

    useEffect(() => {
        fetchHomeWorks(school_class);
    }, [school_class]);

    return (
        <>
            <h1>Homework</h1>
            <table className='table table-sm table-striped table-bordered table-hover'>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Homework</th>
                        <th>Homework Start</th>
                        <th>Homework Complete</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(students).map((key, i) => (
                        students[key].home_works.length > 0 && (
                            <React.Fragment key={i}>
                                <tr>
                                    <td colSpan="5">
                                        <h5>
                                            <Link target="_blank" to={`/homework/${students[key].sr}`}>
                                                {students[key].sr} {students[key].name} <LaunchIcon />
                                            </Link>
                                        </h5>
                                    </td>
                                </tr>
                                {displayStudentRows(students[key])}
                            </React.Fragment>
                        )
                    ))}
                </tbody>
            </table>
        </>
    );
}
