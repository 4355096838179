import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { Button, Form, Alert, Container, Row, Col, Table } from 'react-bootstrap';
import API from "./API";
import noteContext from './NoteContext';
import LaunchIcon from '@mui/icons-material/Launch';
import AssignmentIcon from '@mui/icons-material/Assignment';

export default function SearchStudents() {
    const { homework_id } = useParams();
    const [message, setMessage] = useState("");
    const [isDisabled, setDisabled] = useState(true);
    const [open, setOpen] = useState(false);
    const [students, setStudents] = useState([]);
    const [error, setError] = useState("");
    const [loadingbar, setHideLoading] = useState(false);
    const [student_class, setClass] = useState(1);
    const [student_name, setStudentName] = useState("%");
    const navigate = useNavigate();
    const a = useContext(noteContext);
    let token = a.token;

    const fetchStudentsData = async () => {
        try {
            const res = await API.get(`students/${student_class}/${student_name}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            setStudents(res.data);
            setHideLoading(true);
            setDisabled(true);
        } catch (error) {
            setError("An error occurred while fetching data.");
        }
    };

    useEffect(() => {
        if (a.login_status === null) {
            navigate("/login");
        } else {
            setHideLoading(true);
        }
    }, [a.login_status, navigate]);

    const handleChange = (e) => {
        const { name, checked } = e.target;
        setDisabled(false);
        if (name === "allselect") {
            const checkedvalue = students.map((item) => ({ ...item, isChecked: checked }));
            setStudents(checkedvalue);
        } else {
            const checkedvalue = students.map((item) =>
                item.sr === name ? { ...item, isChecked: checked } : item
            );
            setStudents(checkedvalue);
        }
    };

    const handleDelete = async () => {
        setDisabled(true);
        const filteredArr = students.filter(e => e.isChecked === true);
        const reqdata = { srs: filteredArr.map(student => student.sr) };
        try {
            await API.post("master/delete_students", reqdata, {
                headers: {
                    'content-type': 'application/json', Authorization: 'Bearer ' + token
                }
            });
            setMessage("Selected students have been deleted.");
            setOpen(true);
            fetchStudentsData(); // Refresh the list after deletion
        } catch (error) {
            setError("An error occurred while deleting students.");
        }
    };

    const handlePromote = async () => {
        setDisabled(true);
        const filteredArr = students.filter(e => e.isChecked === true);
        const reqdata = { srs: filteredArr.map(student => student.sr) };
        try {
            await API.post("master/promote_students", reqdata, {
                headers: {
                    'content-type': 'application/json', Authorization: 'Bearer ' + token
                }
            });
            setMessage("Selected students have been promoted to the next class.");
            setOpen(true);
            fetchStudentsData(); // Refresh the list after promotion
        } catch (error) {
            setError("An error occurred while promoting students.");
        }
    };

    if (!loadingbar) return "Loading... please wait";

    return (
        <Container className="sakshmb">
            <div className="mb-3">
                <h1>Manage Students</h1>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Type Class</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Type Class"
                                    onChange={(e) => setClass(e.target.value)}
                                    autoFocus
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Student Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Student Name"
                                    value={student_name}
                                    onChange={(e) => setStudentName(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Button onClick={fetchStudentsData} variant="primary">Search Students</Button>
                        </Col>
                    </Row>
                </Form>
            </div>

            {error && <Alert variant="danger">{error}</Alert>}

            {students.length > 0 && (
                <>
                    <Table striped responsive bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>
                                    <input type="checkbox" name="allselect"
                                        checked={!students.some((user) => user?.isChecked !== true)} onChange={handleChange} />
                                </th>
                                <th>SR</th>
                                <th>Name</th>
                                <th>Class</th>
                                <th>Grade</th>
                                <th>Mobile</th>
                                <th>Father Name</th>
                                <th>Mother Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {students.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <input type="checkbox" name={item.sr} checked={item?.isChecked || false}
                                            onChange={handleChange} />
                                        <Link target="_blank" to={`/student/${item.sr}`}><LaunchIcon /></Link>
                                        <Link target="_blank" to={`/homework/${item.sr}`}><AssignmentIcon /></Link>
                                    </td>
                                    <td>{item.sr}</td>
                                    <td>{item.name}</td>
                                    <td>{item.class}</td>
                                    <td>{item.grade}</td>
                                    <td>{item.mobile}</td>
                                    <td>{item.father_name}</td>
                                    <td>{item.mother_name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <Button onClick={handleDelete} variant="danger" disabled={isDisabled}>Delete Selected</Button>
                    <Button onClick={handlePromote} variant="success" disabled={isDisabled}>Promote to Next Class</Button>
                </>
            )}

            {open && <Alert variant="success" onClose={() => setOpen(false)} dismissible>{message}</Alert>}
        </Container>
    );
}