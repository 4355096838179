
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useContext } from 'react';
import noteContext from './NoteContext';

function BasicExample() {
const navigate = useNavigate();
const { role } = useContext(noteContext);

const navItems = ['Home', 'Logout'];

const transactionItems = role === "10" ? [
'AddStudent', 'UpdateClass', 'homeworks', 'Teacher_report', 'TeacherAsign', 'TeacherAsignReport', 'Student_report', 'Student_print_report', 'teachers'
] : [
'homeworks', 'report'
];

const handleNavigation = (path) => {
navigate(path === "Home" ? "/" : `/${path.toLowerCase()}`);
};

return (
<Navbar expand="lg" style={{ backgroundColor: "rgb(7, 76, 119)" }}>
<Container>
<Navbar.Brand href="#home" style={{ color: '#f8f9fa' }}>HW App</Navbar.Brand>
<Navbar.Toggle aria-controls="basic-navbar-nav" />
<Navbar.Collapse id="basic-navbar-nav">
<Nav className="ms-auto">
{navItems.map((item) => (
<Nav.Link key={item} onClick={() => handleNavigation(item)} style={{ color: '#f8f9fa' }}>{item}</Nav.Link>
))}
<NavDropdown title="Transaction" id="basic-nav-dropdown" style={{ color: '#f8f9fa' }}>
{transactionItems.map((item) => (
<NavDropdown.Item key={item} onClick={() => handleNavigation(item)} style={{ color: '#343a40' }}>
{item}
</NavDropdown.Item>
))}
</NavDropdown>
</Nav>
</Navbar.Collapse>
</Container>
</Navbar>
);
}

export default BasicExample;