import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import noteContext from './NoteContext';
import API from "./API";

function TeacherAssignReport() {
    const [selectedTeacher, setSelectedTeacher] = useState('');
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [assignedHomework, setAssignedHomework] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [classes, setClasses] = useState([1, 2, 3, 4, 5]);
    const [subjects, setSubjects] = useState(["Hindi", "Math", "English", "Social science", "Sanskrit", "General Awareness"]);

    const navigate = useNavigate();
    const { token, login_status } = useContext(noteContext);

    useEffect(() => {
        if (login_status === null) {
            navigate("/login");
        } else {
            fetchTeachersList();
        }
    }, [login_status, navigate]);

    const fetchTeachersList = async () => {
        try {
            const res = await API.get("master/teachers_list", {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log("Teachers List Response:", res.data);
            setTeachers(res.data.teachers);
        } catch (error) {
            console.error("Error fetching teachers list:", error);
        }
    };

 

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const endpoint = `qr/hw/assign_hw`;
            const res = await API.post(endpoint, {
                teacher_id: selectedTeacher,
                class_id: selectedClass,
                subject_id: selectedSubject,
                status: selectedStatus
            }, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            console.log("Assigned Homework Response:", res.data);
            if (res.data && res.data.data) {
                setAssignedHomework(res.data.data);
            } else {
                console.error("Assigned homework data is missing or undefined");
                setAssignedHomework([]);
            }
        } catch (error) {
            console.error("Error fetching assigned homework:", error);
            setAssignedHomework([]);
        }
    };

    return (
        <div className="container mt-4">
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <select
                        className="form-select"
                        value={selectedTeacher}
                        onChange={(e) => {
                            setSelectedTeacher(e.target.value);
                      
                        }}
                    >
                        <option value="" disabled>Select Teacher</option>
                        {teachers.length > 0 ? (
                            teachers.map((teacher) => (
                                <option key={teacher.id} value={teacher.id}>
                                    {teacher.email}
                                </option>
                            ))
                        ) : (
                            <option value="" disabled>No Teachers Available</option>
                        )}
                    </select>
                </div>
                {selectedTeacher && (
                    <div className="mb-3">
                        <select
                            className="form-select"
                            value={selectedClass}
                            onChange={(e) => {
                                setSelectedClass(e.target.value);
                            
                            }}
                        >
                            <option value="" disabled>Select Class</option>
                            {classes.map((cls) => (
                                <option key={cls} value={cls}>
                                    {cls}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {selectedClass && (
                    <div className="mb-3">
                        <select
                            className="form-select"
                            value={selectedSubject}
                            onChange={(e) => setSelectedSubject(e.target.value)}
                        >
                            <option value="" disabled>Select Subject</option>
                            {subjects.map((subject) => (
                                <option key={subject} value={subject}>
                                    {subject}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {selectedSubject && (
                    <div className="mb-3">
                        <select
                            className="form-select"
                            value={selectedStatus}
                            onChange={(e) => setSelectedStatus(e.target.value)}
                        >
                            <option value=""  >Select Status</option>
                            <option value="completed">Completed</option>
                            <option value="pending">Pending</option>
                        </select>
                    </div>
                )}
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>

            <div>
                <hr />
                {assignedHomework.length > 0 ? (
                    <div>
                        <h3>Assigned Homework</h3>
                        <table className="table">
                            <tbody>
                                {assignedHomework.map((homework, index) => (
                                    <tr key={index}>
                                        <td>
                                            Class: {homework.class_id}
                                            <br />
                                            Subject: {homework.subject_id}
                                            <br />
                                            Homework: {homework.hw.homework}
                                        </td>
                                        <td>
                                            <p>Students:</p>
                                            <ul>
                                                {homework.students.map((student) => (
                                                    <li key={student.id}>{student.name}</li>
                                                ))}
                                            </ul>
                                        </td>
                                        <td>
                                            Homework Status: {homework.status}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <p>No assigned homework found.</p>
                )}
            </div>
        </div>
    );
}

export default TeacherAssignReport;