import { useState, useContext } from "react";
import { Button, Form, Alert, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import API from "./API";
import noteContext from './NoteContext';

function AddStudent() {
    const [newStudent, setNewStudent] = useState({ class: "", name: "", grade: "", sr: "" });
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { token } = useContext(noteContext);

    const addStudent = async () => {
        setLoading(true);
        setError("");

        try {
            const res = await API.post("/master/add_student", newStudent, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            setNewStudent({ class: "", name: "", grade: "", sr: "" });
            navigate("/"); // Navigate back to the home page after adding the student
        } catch (error) {
            setError("An error occurred while adding the student.");
            console.error("Error adding student:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container>
            <Row className="mt-3">
                <Col>
                    <Form>
                        <Form.Group>
                            <Form.Label>Class</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Class"
                                value={newStudent.class}
                                onChange={(e) => setNewStudent({ ...newStudent, class: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Name"
                                value={newStudent.name}
                                onChange={(e) => setNewStudent({ ...newStudent, name: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Grade</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Grade"
                                value={newStudent.grade}
                                onChange={(e) => setNewStudent({ ...newStudent, grade: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>SR</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="SR"
                                value={newStudent.sr}
                                onChange={(e) => setNewStudent({ ...newStudent, sr: e.target.value })}
                            />
                        </Form.Group>
                        <Button
                            className="mt-3"
                            onClick={addStudent}
                            variant="primary"
                            disabled={loading}
                        >
                            {loading ? "Adding..." : "Add Student"}
                        </Button>
                    </Form>
                    {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
                </Col>
            </Row>
        </Container>
    );
}

export default AddStudent;
