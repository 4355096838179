import * as React from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useContext, useState, useEffect } from 'react';
import noteContext from './NoteContext';
import API from "./API";
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Form from 'react-bootstrap/Form';

export default function MasterHomework() {
    const [MasterClassesGrades, setMasterClassesGrades] = useState([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [masterSubjects, setMasterSubjects] = useState([]);
    const [homework, setHomework] = useState("");
    const [subject, setSubject] = useState("");
    const [link1, setLink1] = useState("");
    const [link2, setLink2] = useState("");
    const [link3, setLink3] = useState("");
    const [link4, setLink4] = useState("");
    const [link5, setLink5] = useState("");
    const data = useContext(noteContext);
    const navigate = useNavigate();
    let token = data.token;

    const handleSubmit = async () => {
        const filteredArr = MasterClassesGrades.filter(e => e.isChecked === true);
        const reqdata = {
            "class_grades": filteredArr,
            "homework": homework,
            "subject": subject,
            "link1": link1,
            "link2": link2,
            "link3": link3,
            "link4": link4,
            "link5": link5
        };

        try {
            const res = await API.post("/master/setMasterHomework", reqdata, {
                headers: {
                    'content-type': 'application/json', Authorization: 'Bearer ' + token
                }
            });
            const dt = res.data;
            setMessage(dt.message);
            setOpen(true);
        } catch (error) {
            console.error("An error occurred while setting homework:", error);
        }
    };

    const fetchClassGrades = async () => {
        try {
            const res = await API.get("/master/class_grades", {
                headers: {
                    'content-type': 'application/json', Authorization: 'Bearer ' + token
                }
            });
            setMasterClassesGrades(res.data);
        } catch (error) {
            console.error("An error occurred while fetching class grades:", error);
        }
    };

    const fetchSubjects = async () => {
        try {
            const res = await API.get("admin/master/Subjects/%", {
                headers: {
                    'content-type': 'application/json', Authorization: 'Bearer ' + token
                }
            });
            setMasterSubjects(res.data);
        } catch (error) {
            console.error("An error occurred while fetching subjects:", error);
        }
    };

    const handleChange = (e) => {
        const { name, checked } = e.target;
        if (name === "allselect") {
            const checkedValue = MasterClassesGrades.map(item => ({ ...item, isChecked: checked }));
            setMasterClassesGrades(checkedValue);
        } else {
            const checkedValue = MasterClassesGrades.map(item =>
                item.name === name ? { ...item, isChecked: checked } : item
            );
            setMasterClassesGrades(checkedValue);
        }
    };

    useEffect(() => {
        fetchClassGrades();
        fetchSubjects();
    }, []);

    return (
        <>
            <h1>New Homework</h1>
            <table className="table table-auto">
                <thead>
                    <tr>
                        <th>
                            <input type="checkbox" name="allselect"
                                checked={!MasterClassesGrades.some(element => element?.isChecked !== true)} onChange={handleChange} />
                            Select All
                        </th>
                        <th>Class</th>
                        <th>Grade</th>
                    </tr>
                </thead>
                <tbody>
                    {MasterClassesGrades.map((item, index) => (
                        <tr key={index}>
                            <td>
                                <input type="checkbox" name={item.name}
                                    checked={item?.isChecked || false}
                                    onChange={handleChange} />
                            </td>
                            <td>{item.school_class}</td>
                            <td>{item.grade}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="mb-3">
                <Form.Label>Subject</Form.Label>
                <Form.Select onChange={(e) => setSubject(e.target.value)}>
                    <option value="">Select</option>
                    {masterSubjects.map((e) => <option value={e} key={e}>{e}</option>)}
                </Form.Select>
            </div>

            <div className="mb-3">
                <Form.Label>Homework</Form.Label>
                <Form.Control onChange={(e) => setHomework(e.target.value)} type="text" />
            </div>

            <div className="mb-3">
                <Form.Label>Link 1</Form.Label>
                <Form.Control onChange={(e) => setLink1(e.target.value)} type="text" />
            </div>

            <div className="mb-3">
                <Form.Label>Link 2</Form.Label>
                <Form.Control onChange={(e) => setLink2(e.target.value)} type="text" />
            </div>

            <div className="mb-3">
                <Form.Label>Link 3</Form.Label>
                <Form.Control onChange={(e) => setLink3(e.target.value)} type="text" />
            </div>

            <div className="mb-3">
                <Form.Label>Link 4</Form.Label>
                <Form.Control onChange={(e) => setLink4(e.target.value)} type="text" />
            </div>

            <div className="mb-3">
                <Form.Label>Link 5</Form.Label>
                <Form.Control onChange={(e) => setLink5(e.target.value)} type="text" />
            </div>

            <div className="mb-3">
                <Button onClick={handleSubmit} size="large" variant="contained">Set Homework</Button>
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={() => setOpen(false)}
                    message={message}
                />
            </div>
        </>
    );
}