import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Table from 'react-bootstrap/Table';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import API from "./API";
import noteContext from './NoteContext';

function MasterHomeworkList() {
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [error, setError] = useState("");
    const [loadingbar, setHideLoading] = useState(false);
    const [student_class, setClass] = useState("");
    const [grade, setGrade] = useState("");
    const [subject, setSubject] = useState("");
    const [homework_query, setHomeworkQuery] = useState("");
    const [masterHomeworksList, setMasterHomeworks] = useState([]);
    const navigate = useNavigate();
    const a = useContext(noteContext);
    let token = a.token;

    const deleteHomework = async (item) => {
        try {
            const res = await API.post("admin/master/delete_master_homework", item, {
                headers: {
                    'content-type': 'application/json', Authorization: 'Bearer ' + token
                }
            });
            setMessage(res.data.message);
            setOpen(true);
            fetchAPIData(); // Refresh the list after deletion
        } catch (error) {
            console.error("An error occurred while deleting homework:", error);
        }
    };

    const fetchAPIData = async () => {
        const search_query = {
            "class": student_class,
            "grade": grade,
            "subject": subject,
            "homework_query": homework_query
        };

        try {
            const res = await API.post("hw/search_master_homeworks", search_query, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            setMasterHomeworks(res.data.homeworks);
            setHideLoading(true);
        } catch (error) {
            console.error("An error occurred while fetching data:", error);
        }
    };

    useEffect(() => {
        if (a.login_status === null) {
            navigate("/login");
        }
    }, [a.login_status, navigate]);

    return (
        <>
            <Link className="btn btn-primary" to="/add_new_master_homework">Add new master homework</Link>

            <div className='search_form'>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        label="Student Class"
                        value={student_class}
                        onChange={(e) => setClass(e.target.value)}
                    />
                    <TextField
                        label="Grade"
                        value={grade}
                        onChange={(e) => setGrade(e.target.value)}
                    />
                    <TextField
                        label="Subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                    <TextField
                        label="Homework"
                        value={homework_query}
                        onChange={(e) => setHomeworkQuery(e.target.value)}
                    />
                    <Button onClick={fetchAPIData} variant="contained">Fetch Data</Button>
                </Box>
            </div>

            {masterHomeworksList.length > 0 && (
                <div className='table-responsive'>
                    <Table responsive striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Class</th>
                                <th>Grade</th>
                                <th>Subject</th>
                                <th>Homework</th>
                                <th>Created At</th>
                                <th>Action</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {masterHomeworksList.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.school_class}</td>
                                    <td>{item.grade}</td>
                                    <td>{item.subject}</td>
                                    <td>{item.homework}</td>
                                    <td>{item.created_at}</td>
                                    <td>
                                        <Link className="btn btn-primary" to={`/AsignHomeWork/${item.id}`}>
                                            <AssignmentIcon />
                                        </Link>
                                    </td>
                                    <td>
                                        <Button onClick={() => deleteHomework(item)} variant="contained">
                                            <DeleteForeverIcon />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}

            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={() => setOpen(false)}
                message={message}
            />
        </>
    );
}

export default MasterHomeworkList;